import { defineStore } from 'pinia'
import { useI18nLocales } from '@peter-park/vue-common-utils'
import type { RemovableRef } from '@vueuse/core'
import { useStorage } from '@vueuse/core'

export const userSettingsStore = defineStore('userSettings', (): {
  availableLocales: string[]
  navigatorLanguage: Ref<string | undefined>
  userSelectedLanguage: RemovableRef<string>
  latestShopVisited: RemovableRef<string>
  userProfileEnabled: Ref<boolean>
  setLanguage: (locale: string) => void
  setLatestShopVisited: (shop: string) => void
  setUserProfileEnabled: (enabled: boolean) => void
} => {
  const { availableLocales, navigatorLanguage, userSelectedLanguage, setLanguage } = useI18nLocales('de-DE')

  const latestShopVisited = useStorage('latestShopVisited', '')
  function setLatestShopVisited(shop: string) {
    latestShopVisited.value = shop
  }

  const userProfileEnabled = ref(false)
  function setUserProfileEnabled(enabled: boolean) {
    userProfileEnabled.value = enabled
  }
  return {
    availableLocales,
    userSelectedLanguage,
    setLanguage,
    navigatorLanguage,
    latestShopVisited,
    setLatestShopVisited,
    userProfileEnabled,
    setUserProfileEnabled,
  }
})
