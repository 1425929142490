const AT = [
  "AM",
  "B",
  "BA",
  "BB",
  "BD",
  "BG",
  "BH",
  "BK",
  "BL",
  "BM",
  "BN",
  "BP",
  "BR",
  "BZ",
  "DL",
  "DO",
  "E",
  "EF",
  "EU",
  "FB",
  "FE",
  "FF",
  "FK",
  "FR",
  "FV",
  "FW",
  "G",
  "GB",
  "GD",
  "GF",
  "GK",
  "GM",
  "GR",
  "GS",
  "GU",
  "HA",
  "HB",
  "HE",
  "HF",
  "HL",
  "HO",
  "I",
  "IL",
  "IM",
  "JE",
  "JO",
  "JU",
  "JW",
  "K",
  "KB",
  "KD",
  "KF",
  "KG",
  "KI",
  "KK",
  "KL",
  "KO",
  "KR",
  "KS",
  "KU",
  "L",
  "LA",
  "LB",
  "LE",
  "LF",
  "LI",
  "LL",
  "LN",
  "LZ",
  "MA",
  "MD",
  "ME",
  "MI",
  "MT",
  "MU",
  "MZ",
  "N",
  "ND",
  "NK",
  "O",
  "OD",
  "OK",
  "OP",
  "OW",
  "P",
  "PE",
  "PL",
  "PT",
  "RA",
  "RE",
  "RI",
  "RO",
  "S",
  "SB",
  "SD",
  "SE",
  "SK",
  "SL",
  "SO",
  "SP",
  "SR",
  "ST",
  "SV",
  "SW",
  "SZ",
  "T",
  "TA",
  "TD",
  "TK",
  "TU",
  "UU",
  "V",
  "VB",
  "VD",
  "VI",
  "VK",
  "VL",
  "VO",
  "W",
  "WB",
  "WD",
  "WE",
  "WK",
  "WL",
  "WN",
  "WO",
  "WT",
  "WU",
  "WY",
  "WZ",
  "ZE",
  "ZT",
  "ZW"
];

const BA = [
  "SA",
  "BI",
  "PD",
  "DO",
  "TZ",
  "VI",
  "MO",
  "JC",
  "BR",
  "BU",
  "TR",
  "ZV",
  "ZE",
  "MD",
  "KO",
  "GO",
  "BL",
  ""
  // Since 1998 BA is not using district in plates
];

const BG = [
  "A",
  // Burgas Province new
  "\u0411\u0441",
  // Burgas Province old
  "B",
  // Varna Province new
  "\u0412\u043D",
  // Varna Province old
  "BH",
  // Vidin Province new
  "\u0412\u0434",
  // Vidin Province old
  "BP",
  // Vratsa Province new
  "\u0412\u0440",
  // Vratsa Province old
  "BT",
  // Veliko Tarnovo Province new
  "E",
  // Blagoevgrad Province new
  "\u0411\u043B",
  // Blagoevgrad Province old
  "EB",
  // Gabrovo Province new
  "\u0413\u0431",
  // Gabrovo Province old
  "EH",
  // Pleven Province new
  "\u041F\u043B",
  // Pleven Province old
  "K",
  // Kardzhali Province new
  "\u041A\u0436",
  // Kardzhali Province old
  "KH",
  // Kyustendil Province new
  "\u041A\u043D",
  // Kyustendil Province old
  "M",
  // Montana Province new
  "\u041C\u0445",
  // Montana Province old
  "H",
  // Shumen Province new
  "OB",
  // Lovech Province new
  "\u041B\u0447",
  // Lovech Province old
  "P",
  // Ruse Province new
  "\u0420\u0441",
  // Ruse Province old
  "PA",
  // Pazardzhik Province new
  "\u041F\u0437",
  // Pazardzhik Province old
  "PB",
  // Plovdiv Province new
  "\u041F\u0434",
  // Plovdiv Province old
  "PK",
  // Pernik Province new
  "\u041F\u043A",
  // Pernik Province old
  "PP",
  // Razgrad Province new
  "C",
  // Sofia (Capital) new
  "C\u0444",
  // Sofia (Capital) old
  "CH",
  // Sliven Province new
  "C\u043B",
  // Sliven Province old
  "CM",
  // Smolyan Province new
  "\u0421\u043C",
  // Smolyan Province old
  "CO",
  // Sofia Province new
  "CC",
  // Silistra Province new
  "C\u0441",
  // Silistra Province old
  "CT",
  // Stara Zagora Province new
  "\u0421\u0442\u0417",
  // Stara Zagora Province old
  "T",
  // Targovishte Province new
  "T\u0449",
  // Targovishte Province old
  "TX",
  // Dobrich Province new
  "T\u0445",
  // Dobrich Province old
  "Y",
  // Yambol Province new
  "\u042F\u0431",
  // Yambol Province old
  "X",
  // Haskovo Province new
  "X\u0441"
  // Haskovo Province old
];

const CH = [
  "AG",
  "AI",
  "AR",
  "BE",
  "BL",
  "BS",
  "FR",
  "GE",
  "GL",
  "GR",
  "JU",
  "LU",
  "NE",
  "NW",
  "OW",
  "SG",
  "SH",
  "SO",
  "SZ",
  "TG",
  "TI",
  "UR",
  "VD",
  "VS",
  "ZG",
  "ZH"
];

const DE = [
  "A",
  "AA",
  "AB",
  "ABG",
  "ABI",
  "AC",
  "AE",
  "AH",
  "AIB",
  "AIC",
  "AK",
  "AL",
  "ALF",
  "ALS",
  "ALZ",
  "AM",
  "AN",
  "ANA",
  "ANG",
  "ANK",
  "A\xD6",
  "AP",
  "APD",
  "AR",
  "ARN",
  "ART",
  "AS",
  "ASD",
  "ASL",
  "ASZ",
  "AT",
  "AU",
  "AUR",
  "AW",
  "AZ",
  "AZE",
  "B",
  "BA",
  "BAD",
  "BAR",
  "BB",
  "BBG",
  "BC",
  "BCH",
  "BE",
  "BED",
  "BEI",
  "BEL",
  "BER",
  "BF",
  "BGD",
  "BGL",
  "BH",
  "BI",
  "BID",
  "BIN",
  "BIR",
  "BIT",
  "BIW",
  "BK",
  "BKS",
  "BL",
  "BLB",
  "BLK",
  "BM",
  "BN",
  "BNA",
  "BO",
  "B\xD6",
  "BOG",
  "BOH",
  "BOR",
  "BOT",
  "BR",
  "BRA",
  "BRB",
  "BRG",
  "BRI",
  "BRK",
  "BRL",
  "BRV",
  "BS",
  "BSB",
  "BSK",
  "BT",
  "BTF",
  "BU",
  "B\xDCD",
  "BUL",
  "B\xDCR",
  "B\xDCS",
  "B\xDCZ",
  "BZ",
  "BZA",
  "C",
  "CA",
  "CAS",
  "CB",
  "CE",
  "CHA",
  "CLP",
  "CLZ",
  "CO",
  "COC",
  "COE",
  "CR",
  "CUX",
  "CW",
  "D",
  "DA",
  "DAH",
  "DAN",
  "DAU",
  "DBR",
  "DD",
  "DE",
  "DEG",
  "DEL",
  "DGF",
  "DH",
  "DI",
  "DIL",
  "DIN",
  "DIZ",
  "DKB",
  "DL",
  "DLG",
  "DM",
  "DN",
  "DO",
  "DON",
  "DS",
  "DT",
  "DU",
  "DUD",
  "D\xDCW",
  "DW",
  "DZ",
  "E",
  "EA",
  "EB",
  "EBE",
  "EBN",
  "EBS",
  "ECK",
  "ED",
  "EE",
  "EF",
  "EG",
  "EH",
  "EHI",
  "EI",
  "EIC",
  "EIH",
  "EIL",
  "EIN",
  "EIS",
  "EL",
  "EM",
  "EMD",
  "EMS",
  "EN",
  "ER",
  "ERB",
  "ERH",
  "ERK",
  "ERZ",
  "ES",
  "ESA",
  "ESB",
  "ESW",
  "EU",
  "EUT",
  "EW",
  "F",
  "FAL",
  "FB",
  "FD",
  "FDB",
  "FDS",
  "FEU",
  "FF",
  "FFB",
  "FG",
  "FH",
  "FI",
  "FKB",
  "FL",
  "FL\xD6",
  "FN",
  "FO",
  "FOR",
  "FR",
  "FRG",
  "FRI",
  "FRW",
  "FS",
  "FT",
  "FTL",
  "F\xDC",
  "F\xDCS",
  "FW",
  "FZ",
  "G",
  "GA",
  "GAN",
  "GAP",
  "GC",
  "GD",
  "GDB",
  "GE",
  "GEL",
  "GEM",
  "GEO",
  "GER",
  "GF",
  "GG",
  "GHA",
  "GHC",
  "GI",
  "GK",
  "GL",
  "GLA",
  "GM",
  "GMN",
  "GN",
  "GNT",
  "G\xD6",
  "GOA",
  "GOH",
  "GP",
  "GR",
  "GRA",
  "GRH",
  "GRI",
  "GRM",
  "GRS",
  "GRZ",
  "GS",
  "GT",
  "GTH",
  "G\xDC",
  "GUB",
  "GUN",
  "GV",
  "GVM",
  "GW",
  "GZ",
  "H",
  "HA",
  "HAB",
  "HAL",
  "HAM",
  "HAS",
  "HB",
  "HBN",
  "HBS",
  "HC",
  "HCH",
  "HD",
  "HDH",
  "HDL",
  "HE",
  "HEB",
  "HEF",
  "HEI",
  "HER",
  "HET",
  "HF",
  "HG",
  "HGN",
  "HGW",
  "HH",
  "HHM",
  "HI",
  "HIG",
  "HIP",
  "HK",
  "HL",
  "HM",
  "HM\xDC",
  "HN",
  "HO",
  "HOG",
  "HOH",
  "HOL",
  "HOM",
  "HOR",
  "H\xD6S",
  "HOT",
  "HP",
  "HR",
  "HRO",
  "HS",
  "HSK",
  "HST",
  "HU",
  "H\xDCN",
  "HUS",
  "HV",
  "HVL",
  "HW",
  "HWI",
  "HX",
  "HY",
  "HZ",
  "IGB",
  "IK",
  "IL",
  "ILL",
  "IN",
  "IS",
  "IZ",
  "J",
  "JB",
  "JE",
  "JEV",
  "JL",
  "J\xDCL",
  "K",
  "KA",
  "KAR",
  "KB",
  "KC",
  "KE",
  "KEH",
  "KEL",
  "KEM",
  "KF",
  "KG",
  "KH",
  "KI",
  "KIB",
  "KK",
  "KL",
  "KLE",
  "KLZ",
  "KM",
  "KN",
  "KO",
  "K\xD6N",
  "K\xD6T",
  "K\xD6Z",
  "KR",
  "KRU",
  "KS",
  "KT",
  "KU",
  "K\xDCN",
  "KUS",
  "KW",
  "KY",
  "KYF",
  "L",
  "LA",
  "LAN",
  "LAT",
  "LAU",
  "LB",
  "LBS",
  "LBZ",
  "LC",
  "LD",
  "LDK",
  "LDS",
  "LE",
  "LEO",
  "LER",
  "LEV",
  "LF",
  "LG",
  "LH",
  "LI",
  "LIB",
  "LIF",
  "LIN",
  "LIP",
  "LK",
  "LL",
  "LM",
  "LN",
  "L\xD6",
  "L\xD6B",
  "LOH",
  "LOS",
  "LP",
  "LR",
  "LRO",
  "LS",
  "LSZ",
  "LU",
  "L\xDCD",
  "LUK",
  "L\xDCN",
  "LUP",
  "LWL",
  "M",
  "MA",
  "MAB",
  "MAI",
  "MAK",
  "MAL",
  "MAR",
  "MB",
  "MC",
  "MD",
  "ME",
  "MED",
  "MEG",
  "MEI",
  "MEK",
  "MEL",
  "MEP",
  "MER",
  "MES",
  "MET",
  "MG",
  "MGH",
  "MGN",
  "MH",
  "MHL",
  "MI",
  "MIL",
  "MK",
  "MKK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MOD",
  "MOL",
  "MON",
  "MOS",
  "MQ",
  "MR",
  "MS",
  "MSE",
  "MSH",
  "MSP",
  "MST",
  "MT",
  "MTK",
  "MTL",
  "MUC",
  "M\xDC",
  "M\xDCB",
  "M\xDCL",
  "M\xDCN",
  "M\xDCR",
  "MW",
  "MY",
  "MYK",
  "MZ",
  "MZG",
  "N",
  "NAB",
  "NAI",
  "NAU",
  "NB",
  "ND",
  "NDH",
  "NE",
  "NEA",
  "NEB",
  "NEC",
  "NEN",
  "NES",
  "NEU",
  "NEW",
  "NF",
  "NH",
  "NI",
  "NIB",
  "NK",
  "NM",
  "NMB",
  "NMS",
  "N\xD6",
  "NOH",
  "NOL",
  "NOM",
  "NOR",
  "NP",
  "NR",
  "NR\xDC",
  "NT",
  "NU",
  "NVP",
  "NW",
  "NWM",
  "NY",
  "NZ",
  "OA",
  "OAL",
  "OB",
  "OBB",
  "OBG",
  "OC",
  "OCH",
  "OD",
  "OE",
  "OF",
  "OG",
  "OH",
  "OHA",
  "\xD6HR",
  "OHV",
  "OHZ",
  "OK",
  "OL",
  "OLD",
  "OP",
  "OPR",
  "OR",
  "OS",
  "OSL",
  "OTT",
  "OTW",
  "OVI",
  "OVL",
  "OVP",
  "OZ",
  "P",
  "PA",
  "PAF",
  "PAN",
  "PAR",
  "PB",
  "PCH",
  "PE",
  "PEG",
  "PER",
  "PF",
  "PI",
  "PIR",
  "PK",
  "PL",
  "PL\xD6",
  "PM",
  "PN",
  "PR",
  "PR\xDC",
  "PS",
  "PW",
  "PZ",
  "QFT",
  "QLB",
  "R",
  "RA",
  "RC",
  "RD",
  "RDG",
  "RE",
  "REG",
  "REH",
  "REI",
  "RG",
  "RH",
  "RI",
  "RID",
  "RIE",
  "RL",
  "RM",
  "RN",
  "RO",
  "ROD",
  "ROF",
  "ROK",
  "ROL",
  "ROS",
  "ROT",
  "ROW",
  "RP",
  "RS",
  "RSL",
  "RT",
  "RU",
  "R\xDCD",
  "R\xDCG",
  "RV",
  "RW",
  "RY",
  "RZ",
  "S",
  "SAB",
  "SAD",
  "S\xC4K",
  "SAN",
  "SAW",
  "SB",
  "SBG",
  "SBK",
  "SC",
  "SCZ",
  "SDH",
  "SDL",
  "SDT",
  "SE",
  "SEB",
  "SEE",
  "SEF",
  "SEL",
  "SF",
  "SFA",
  "SFB",
  "SFT",
  "SG",
  "SGH",
  "SHA",
  "SHG",
  "SHK",
  "SHL",
  "SI",
  "SIG",
  "SIM",
  "SK",
  "SL",
  "SLE",
  "SLF",
  "SLG",
  "SLK",
  "SLN",
  "SLS",
  "SL\xDC",
  "SLZ",
  "SM",
  "SM\xDC",
  "SN",
  "SNH",
  "SO",
  "SOB",
  "SOG",
  "SOK",
  "SOL",
  "S\xD6M",
  "SON",
  "SP",
  "SPB",
  "SPN",
  "SPR",
  "SR",
  "SRB",
  "SRO",
  "ST",
  "STA",
  "STB",
  "STD",
  "STE",
  "STH",
  "STL",
  "STO",
  "SU",
  "SUL",
  "S\xDCW",
  "SW",
  "SWA",
  "SY",
  "SZ",
  "SZB",
  "TBB",
  "TDO",
  "TE",
  "TET",
  "TF",
  "TG",
  "TIR",
  "TO",
  "T\xD6L",
  "T\xD6N",
  "TP",
  "TR",
  "TS",
  "TT",
  "T\xDC",
  "TUT",
  "\xDCB",
  "UE",
  "UEM",
  "UER",
  "UFF",
  "UH",
  "UL",
  "UM",
  "UN",
  "USI",
  "V",
  "VAI",
  "VB",
  "VEC",
  "VER",
  "VG",
  "VIB",
  "VIE",
  "VIT",
  "VK",
  "VL",
  "VOF",
  "VOH",
  "VR",
  "VS",
  "W",
  "WA",
  "WAF",
  "WAK",
  "WAN",
  "WAR",
  "WAT",
  "WB",
  "WBS",
  "WD",
  "WDA",
  "WE",
  "WEB",
  "WEG",
  "WEL",
  "WEM",
  "WEN",
  "WER",
  "WES",
  "WF",
  "WG",
  "WHV",
  "WI",
  "WIL",
  "WIS",
  "WIT",
  "WIZ",
  "WK",
  "WL",
  "WLG",
  "WM",
  "WMS",
  "WN",
  "WND",
  "WO",
  "WOB",
  "WOH",
  "WOL",
  "WOR",
  "WOS",
  "WR",
  "WRN",
  "WS",
  "WSF",
  "WST",
  "WSW",
  "WT",
  "WTL",
  "WTM",
  "W\xDC",
  "WUG",
  "W\xDCM",
  "WUN",
  "WUR",
  "WW",
  "WZ",
  "WZL",
  "Z",
  "ZE",
  "ZEL",
  "ZI",
  "ZIG",
  "ZP",
  "ZR",
  "ZS",
  "ZW",
  "ZZ"
];

const HR = [
  "BJ",
  "BM",
  "\u010CK",
  "DA",
  "DE",
  "DJ",
  "DU",
  "GS",
  "HV",
  // Military
  "IM",
  "KA",
  "KC",
  "KR",
  "KT",
  "K\u017D",
  "MA",
  "NA",
  "NG",
  "OG",
  "OS",
  "PU",
  "P\u017D",
  "RI",
  "RH",
  // Export car
  "SB",
  "SK",
  "SL",
  "ST",
  "\u0160I",
  "VK",
  "VT",
  "VU",
  "V\u017D",
  "ZD",
  "ZG",
  "\u017DU",
  ""
  // Police and diplomatic cars haven't district
];

const RO = [
  "B",
  "AB",
  "AG",
  "AR",
  "BC",
  "BH",
  "BN",
  "BR",
  "BT",
  "BV",
  "BZ",
  "CJ",
  "CL",
  "CS",
  "CT",
  "CV",
  "DB",
  "DJ",
  "GJ",
  "GL",
  "GR",
  "HD",
  "HR",
  "IF",
  "IL",
  "IS",
  "MH",
  "MM",
  "MS",
  "NT",
  "OT",
  "PH",
  "SB",
  "SJ",
  "SM",
  "SV",
  "TL",
  "TM",
  "TR",
  "VL",
  "VN",
  "VS"
];

const RS = [
  "AL",
  "AR",
  "AC",
  "BB",
  "BG",
  "BO",
  "BP",
  "BT",
  "B\u0106",
  "BU",
  "B\u010C",
  "VA",
  "VB",
  "VL",
  "VP",
  "VR",
  "VS",
  "V\u0160",
  "GL",
  "GM",
  "DE",
  "\u0110A",
  "ZA",
  "ZR",
  "IN",
  "IC",
  "JA",
  "KA",
  "KC",
  "KV",
  "KG",
  "K\u017D",
  "KI",
  "KL",
  "KM",
  "KO",
  "K\u0160",
  "LB",
  "LE",
  "LO",
  "LU",
  "NV",
  "NG",
  "NI",
  "NP",
  "NS",
  "PA",
  "PB",
  "PE",
  "P\u017D",
  "PZ",
  "PI",
  "PK",
  "PN",
  "PO",
  "PP",
  "PR",
  "PT",
  "RA",
  "RPE",
  "RU",
  "SA",
  "SM",
  "SV",
  "SD",
  "SJ",
  "SO",
  "SP",
  "ST",
  "SU",
  "SC",
  "TO",
  "TS",
  "TT",
  "\u0106U",
  "UB",
  "UE",
  "UR",
  "\u010CA",
  "\u0160A",
  "\u0160I"
];

const SI = [
  "CE",
  "GO",
  "KK",
  "KP",
  "KR",
  "LJ",
  "MB",
  "MS",
  "NM",
  "PO",
  "SG"
];

const SK = [
  "BA",
  "BD",
  "BE",
  "BI",
  "BL",
  "BT",
  // Bratislava
  "BB",
  "BC",
  "BK",
  // Banská Bystrica
  "BJ",
  // Bardejov
  "BN",
  // Bánovce nad Bebravou
  "BR",
  // Brezno
  "BS",
  // Banská Štiavnica
  "BY",
  // Bytča
  "CA",
  // Čadca
  "DK",
  // Dolný Kubín
  "DS",
  // Dunajská Streda
  "DT",
  // Detva
  "GA",
  // Galanta
  "GL",
  // Gelnica
  "HC",
  // Hlohovec
  "HE",
  // Humenné
  "IL",
  // Ilava
  "KA",
  // Krupina
  "KE",
  "KC",
  "KI",
  // Košice
  "KK",
  // Kežmarok
  "KM",
  // Kysucké Nové Mesto
  "KN",
  // Komárno
  "KS",
  // Košice-okolie
  "LC",
  // Lučenec
  "LE",
  // Levoča
  "LM",
  // Liptovský Mikuláš
  "LV",
  "LL",
  // Levice
  "MA",
  // Malacky
  "MI",
  // Michalovce
  "ML",
  // Medzilaborce
  "MT",
  // Martin
  "MY",
  // Myjava
  "NR",
  "NI",
  "NT",
  // Nitra
  "NM",
  // Nové Mesto nad Váhom
  "NO",
  // Námestovo
  "NZ",
  "NC",
  // Nové Zámky
  "PB",
  // Považská Bystrica
  "PD",
  // Prievidza
  "PE",
  // Partizánske
  "PK",
  // Pezinok
  "PN",
  // Piešťany
  "PO",
  "PV",
  "PS",
  // Prešov
  "PP",
  // Poprad
  "PT",
  // Poltár
  "PU",
  // Púchov
  "RA",
  // Revúca
  "RK",
  // Ružomberok
  "RS",
  // Rimavská Sobota
  "RV",
  // Rožňava
  "SA",
  // Šaľa
  "SB",
  // Sabinov
  "SC",
  // Senec
  "SE",
  // Senica
  "SI",
  // Skalica
  "SK",
  // Svidník
  "SL",
  // Stará Ľubovňa
  "SN",
  // Spišská Nová Ves
  "SO",
  // Sobrance
  "SP",
  // Stropkov
  "SV",
  // Snina
  "TT",
  "TA",
  "TB",
  // Trnava
  "TN",
  "TC",
  "TE",
  // Trenčín
  "TO",
  // Topoľčany
  "TR",
  // Turčianske Teplice
  "TS",
  // Tvrdošín
  "TV",
  // Trebišov
  "VK",
  // Veľký Krtíš
  "VT",
  // Vranov nad Topľou
  "ZA",
  "ZI",
  "ZL",
  // Žilina
  "ZC",
  // Žarnovica
  "ZH",
  // Žiar nad Hronom
  "ZM",
  // Zlaté Moravce
  "ZV",
  // Zvolen
  "EE",
  // Diplomatic
  "C",
  // Import
  "EL"
  // Electric
];

const UA = [
  "AK",
  "MA",
  "TK",
  "MK",
  "AB",
  "KB",
  "IM",
  "PI",
  "AC",
  "KC",
  "CM",
  "TC",
  "AE",
  "KE",
  "PP",
  "MI",
  "AH",
  "KH",
  "TH",
  "MH",
  "AM",
  "KM",
  "TM",
  "MB",
  "AO",
  "KO",
  "MT",
  "MO",
  "AP",
  "KP",
  "TP",
  "MP",
  "AT",
  "KT",
  "TO",
  "XC",
  "AI",
  "KI",
  "TI",
  "EE",
  "AA",
  "KA",
  "TT",
  "KK",
  "BA",
  "HA",
  "XA",
  "EA",
  "BB",
  "HB",
  "EP",
  "EB",
  "BC",
  "HC",
  "CC",
  "EC",
  "BE",
  "HE",
  "XE",
  "XH",
  "BH",
  "HH",
  "OO",
  "EH",
  "BI",
  "HI",
  "XI",
  "EI",
  "BK",
  "HK",
  "XK",
  "EK",
  "BM",
  "HM",
  "XM",
  "EM",
  "BO",
  "HO",
  "XO",
  "EO",
  "AX",
  "KX",
  "XX",
  "EX",
  "BT",
  "HT",
  "XT",
  "ET",
  "BX",
  "HX",
  "OX",
  "PX",
  "CA",
  "IA",
  "OA",
  "PA",
  "CB",
  "IB",
  "OB",
  "PB",
  "CE",
  "IE",
  "OE",
  "PE",
  "CH",
  "IH",
  "OH",
  "PH"
];

const districts = { AT, BA, BG, CH, DE, HR, RO, RS, SI, SK, UA };

const IsoCountryUppercaseValues = [
  "AT",
  "BA",
  "BE",
  "BG",
  "BY",
  "CH",
  "CZ",
  "DE",
  "DK",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IT",
  "LU",
  "LT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "RS",
  "SK",
  "SI",
  "SE",
  "UA",
  "UK",
  "XX"
];
const supportedCountriesList = [
  ...IsoCountryUppercaseValues
];
const supportedCountries = {
  AT: "AT",
  BE: "BE",
  BA: "BA",
  BG: "BG",
  BY: "BY",
  CH: "CH",
  CZ: "CZ",
  DE: "DE",
  DK: "DK",
  ES: "ES",
  FI: "FI",
  FR: "FR",
  GR: "GR",
  HU: "HU",
  HR: "HR",
  IT: "IT",
  LU: "LU",
  LT: "LT",
  NL: "NL",
  NO: "NO",
  PL: "PL",
  PT: "PT",
  SE: "SE",
  RS: "RS",
  RO: "RO",
  SK: "SK",
  SI: "SI",
  UA: "UA",
  UK: "UK",
  XX: "XX"
};
const countryCodeToIVRCode = /* @__PURE__ */ new Map([
  ["AT", "A"],
  ["BE", "B"],
  ["BA", "BIH"],
  ["BG", "BG"],
  // ['BY', 'BY'],  non-eu country codes are not visible in LP yet
  // ['CH', 'CH'],
  ["CZ", "CZ"],
  ["DE", "D"],
  ["DK", "DK"],
  ["ES", "E"],
  ["FI", "FIN"],
  ["FR", "F"],
  ["GR", "GR"],
  ["HU", "H"],
  ["HR", "HR"],
  ["IT", "I"],
  ["LU", "L"],
  ["LT", "LT"],
  ["NL", "NL"],
  ["NO", "N"],
  ["PL", "PL"],
  ["PT", "P"],
  ["SE", "S"],
  ["RS", "SRB"],
  ["RO", "RO"],
  ["SK", "SK"],
  ["SI", "SLO"]
  // ['UA', 'UA'],
  // ['UK', 'UK'], non-eu country since brexit 2021
  // ['XX', 'XX'],
]);

const defaultseparatorRegex = /[.\-\s]?/;
function validatePlate(plate, country, useLiberalseparators, useRegionCodes) {
  return getCountryRegex(country, useLiberalseparators, useRegionCodes).test(plate);
}
function getCountryRegex(country, useLiberalseparators, useRegionCodes) {
  let regionRegex;
  let registrationRegex;
  let separatorRegex = useLiberalseparators ? defaultseparatorRegex : /(?:)/;
  let exceptionRegex;
  switch (country) {
    case supportedCountries.AT:
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-/;
      regionRegex = useRegionCodes ? RegExp(`(${districts.AT.join("|")})`) : /[A-Z]{1,2}/;
      registrationRegex = /((?=.*[A-PR-Z])(?=.*\d)[A-PR-Z0-9]{3,6})/;
      return RegExp(
        `^${regionRegex.source}${separatorRegex.source}?${registrationRegex.source}$`
      );
    case supportedCountries.BE:
      return /^(?=[0-9A-Z]{5})\d*[A-Z]\d*$|^(?=[0-9A-Z]{5})\d*[A-Z]{2}\d*$|^[A-Z]\d{3}[A-Z]$|\d{3}[A-Z]{3}|^[A-Z]{3}\d{3}$|^\d-[A-Z]{3}-\d{3}$|^[A-Z0-9-]{1,8}$|^9-\d{2}-[A-Z]-\d{2}$|^9-\d{4}-[A-Z]$|^[WX][A-Z]-\d{2}-[A-Z]{3}$/;
    case supportedCountries.BA:
      regionRegex = useRegionCodes ? RegExp(`(${districts.BA.join("|")})`) : /[A-ZČĆŽĐŠ]{0,2}/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-?/;
      registrationRegex = /([AEOJKMT]?\d{2}[\s-]?[AEOJKMT][\s-]?\d{3}$|TA[\s-]?\d{6}$|TT[\s-]?\d{6}$|\d{4}[\s-]?[A-Z]{2}$|\d{5}[\s-]?[A-Z]$|\d{3}[\s-]?[AEOJKMT][\s-]?\d{3})/;
      return RegExp(
        `^${regionRegex.source}?(${separatorRegex.source})?${registrationRegex.source}$`
      );
    case supportedCountries.BG:
      regionRegex = useRegionCodes ? RegExp(`(${districts.BG.join("|")})`) : /[A-ZА-Я]{0,2}/;
      registrationRegex = /[\s-]?\d{4}[\s-]?[A-ZА-Я]{2}/;
      exceptionRegex = /(\d{3}[\s-]?[HMB][\s-]?\d{3})|(C[CT]?[\s-]?\d{4}[\s-]?\d{2})|(E[AM][\s-]?\d{4}[\s-]?[A-ZА-Я]{2})|((XX|XH)[\s-]?\d{4}[\s-]?\d{2})|(BA[\s-]?\d{3}[\s-]?\d{3})|(CP[\s-]?\d{2}[\s-]?\d{3})/;
      return RegExp(`^(${regionRegex.source}${registrationRegex.source})|(${exceptionRegex.source})$`);
    case supportedCountries.BY:
      return /^((\d{4}[A-Z]{2}-\d)|([A-Z]{2}\d{4}-\d)|([A-Z]\d{4}[A-Z]-\d)|([A-Z]{3}\d{3}-\d))$/;
    case supportedCountries.CH:
      regionRegex = useRegionCodes ? RegExp(`(${districts.CH.join("|")})`) : /[A-Z]{2}/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-?/;
      registrationRegex = new RegExp(`((\\d{1,6})|(\\d{1,5}${separatorRegex.source}(V|U|Z)))`);
      return RegExp(
        `^${regionRegex.source}${separatorRegex.source}${registrationRegex.source}$`
      );
    case supportedCountries.CZ:
      return /^(\d[A-Z]([\dA-Z])-?\d{4})$|^(?=.*\d)(?!.*([GOQW]|CH))([0-9A-Z]{3}-?[0-9A-Z]{5})$|^([A-Z]{2,3}-?\d{2}-?\d{2})$|^\d{3}[A-Z]{2}\d{2}$|^(EL)\d-?\d{2}[A-Z]{2}$|^(\d[A-Z]{2}-?[A-Z]\d{3})$/;
    case supportedCountries.DE:
      regionRegex = useRegionCodes ? RegExp(`(${districts.DE.join("|")})`) : /[A-ZÖÜÄ]{1,3}/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-/;
      registrationRegex = /[A-Z]{1,2}\s?[1-9]\d{0,3}(\s?[E|H])?(?!.)/;
      return RegExp(
        `^${regionRegex.source}${separatorRegex.source}${registrationRegex.source}$`
      );
    case supportedCountries.DK:
      return /(^[A-Z]{1,2}\d{5}$)|(^[A-ZÆØÅ0-9]{2,7}$)/;
    case supportedCountries.ES:
      return /(^(\d{4}[BCDFGHJ-NPRSTV-Z]{3})$)/;
    case supportedCountries.FI:
      return /^(([A-ZÅÄÖ]{2,3}-[1-9]\d{1,2})|(C-\d{2,4})|(CD-\d{2,4})|([1-9]\d-[A-ZÅÄÖ]{2,3}))$/;
    case supportedCountries.FR:
      return /^(?!.*[IOU])((?!.*SS|WW)([A-Z]{2})-\d{3}-(?!.*SS)([A-Z]{2}))$|^\d{1,4}-?[A-Z]{1,3}\d{2,3}$/;
    case supportedCountries.GR:
      return /(^([A-Z]{3}-\d{4})$)/;
    case supportedCountries.HU:
      return /^[A-Z]{3}-(\d{2}[1-9]|\d[1-9]\d|[1-9]\d{2})$|^[A-Z]{4}-(\d[1-9]|[1-9]\d)$|^[A-Z]{4}-(\d{2}[1-9]|\d[1-9]\d|[1-9]\d{2})$|^[A-Z]{5}-[1-9]$|^[A-Z]{5}-(\d[1-9]|[1-9]\d)$|^[A-Z]{6}-[1-9]$|^[A-Z]-\d{5}$|^[A-Z]{2}-\d{5}$|^CD\d{3}-\d{3}$|^I\d{3}-[A-Z]{2}$|^I\d{2}-[A-Z]{2}\d{2}$/;
    case supportedCountries.HR:
      regionRegex = useRegionCodes ? RegExp(`(${districts.HR.join("|")})`) : /[A-ZČĆŽĐŠ]{0,2}/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-/;
      registrationRegex = /(\d{3,4}[\s-]?[A-ZČĆŽĐŠ]{1,2}$|[A-ZČĆŽĐŠ]{4,5}[\s-]?\d\d?$|\d{3}[\s-]?\d{3}$|\d{3}[\s-]?[ACM][\s-]?\d{3}$)/;
      return RegExp(
        `^${regionRegex.source}${separatorRegex.source}?${registrationRegex.source}$`
      );
    case supportedCountries.IT:
      regionRegex = /(?!.*[IOQU])[A-Z]{2}/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-/;
      registrationRegex = /\d{3}[A-Z]{2}$|^[A-Z]{2}\d{5}/;
      return RegExp(
        `^${regionRegex.source}${separatorRegex.source}?${registrationRegex.source}$`
      );
    case supportedCountries.LU:
      return /^[A-Z]{1,2}\d{3,4}$|^\d{3,5}L?$|^[A-Z]{1,2}\d{2,3}L?$|^[A-Z]\d{2,4}L?$/;
    case supportedCountries.LT:
      return /^(([A-Z]{3}[\s-]?\d{3})|(\d{4,5}[A-Z]{2})|(P\d{5})|(\d{2}[\s-]?\d[\s-]?\d{3})|(T\d{5})|(H\d{5}))$/;
    case supportedCountries.NL:
      return /(^[A-Z]{2}-\d{2}-\d{2}$)|(^\d{2}-\d{2}-[A-Z]{2}$)|(^\d{2}-[A-Z]{2}-\d{2}$)|(^[A-Z]{2}-\d{2}-[A-Z]{2}$)|(^[A-Z]{2}-[A-Z]{2}-\d{2}$)|(^\d{2}-[A-Z]{2}-[A-Z]{2}$)|(^\d{2}-[A-Z]{3}-\d$)|(^\d-[A-Z]{3}-\d{2}$)|(^[A-Z]{2}-\d{3}-[A-Z]$)|(^[A-Z]-\d{3}-[A-Z]{2}$)|(^[A-Z]{3}-\d{2}-[A-Z]$)|(^[A-Z]{2}-\d{2}-[A-Z]{2}$)|(^[A-Z]-\d{2}-[A-Z]{3}$)|(^\d-[A-Z]{2}-\d{3}$)|(^\d{3}-[A-Z]{2}-\d$)/;
    case supportedCountries.NO:
      return /^(([A-Z]{2}\d{5})|([0-9A-Z]{2,7}))$/;
    case supportedCountries.PL:
      return /(^[A-PR-Z]-?(?!.*[BDIOQZ])(\d{2}([\dA-Z])|\d[A-Z]([\dA-Z])|[A-Z]\d([\dA-Z])|[A-Z]{2}\d)$)|(^[A-PR-Z]{2}-?(?!.*[BDIOQZ])(\d{4,5}|\d{4}[A-Z]|\d{3}[A-Z]{1,2}|\d[A-Z]([A-Z\d])\d{2}|[1-9][A-Z]\d{2}|\d{2}[A-Z][1-9]|\d{2}[A-Z]{2}|[A-Z]{2}\d{2})$)|(^[A-PR-Z]{3}-?(?!.*[BDIOQZ])(\d{5}|\d([\dA-Z])|[ACE-HJ-NP-Z]\d|[A-Z]\d{2}([\dA-Z])|\d{2}[A-Z]([1-9ACE-HJ-NP-Z])|[1-9][A-Z]\d{2}|[1-9][A-Z]{2}[1-9]|\d{3}([A-Z\d])[A-Z]|[A-Z][1-9][A-Z]|[A-Z]{2}\d{2})$)|(^[A-PR-Z](\d(-?)\d{3}[A-PR-Z]|\d(-?)[A-PR-Z]{5})$)|(^[A-PR-Z]\d{2}(-?)\d{2}P\d{2}$)|(^[A-PR-Z]\d(-?)([A-PR-Z]{2,3}\d{1,2}|[A-PR-Z]{2,4}\d|[A-PR-Z]{1,3}\d{2}|[A-PR-Z]{3,5}))$/;
    case supportedCountries.PT:
      return /^([A-Z]{2}-\d{2}-\d{2}$)|(\d{2}-\d{2}-[A-Z]{2}$)|(\d{2}-[A-Z]{2}-\d{2}$)|([A-Z]{2}-\d{2}-[A-Z]{2}$)|([A-Z]{2}\d{4}$)|(\d{4}[A-Z]{2}$)|(\d{2}[A-Z]{2}\d{2}$)|([A-Z]{2}\d{2}[A-Z]{2}$)/;
    case supportedCountries.RO:
      regionRegex = useRegionCodes ? RegExp(`(${districts.RO.join("|")})`) : /[A-Z]{1,2}/;
      registrationRegex = /\d{2,3}[A-Z]{3}$/;
      return RegExp(
        `^${regionRegex.source}${registrationRegex.source}`
      );
    case supportedCountries.SE:
      return /^[A-HJ-PR-UW-Z]{3}((\d{3})|(\d{2}[A-HJ-NPR-UW-Z]))$/;
    case supportedCountries.RS:
      regionRegex = useRegionCodes ? RegExp(`(${districts.RS.join("|")})`) : /[A-ZČĆŽĐŠ]{0,3}/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-/;
      registrationRegex = /(\d{3,4}-[A-ZČĆŽĐŠ]{2}$|\d{2}-[A-ZČĆŽĐŠ]{3}$|\d{3}-\d{2,3}$|[A-ZČĆŽĐŠ]{2}-\d{3}$|\d{5}-TX$|\d{2,3}-[A-Z]-\d{3}$)/;
      return RegExp(
        `^${regionRegex.source}${separatorRegex.source}${registrationRegex.source}$`
      );
    case supportedCountries.SK:
      regionRegex = useRegionCodes ? RegExp(`(${districts.SK.join("|")})`) : /([A-Z]{2})/;
      separatorRegex = useLiberalseparators ? defaultseparatorRegex : /-/;
      registrationRegex = /((\d{3}[A-Z]{2})|([A-Z]{4}[A-Z\d])|[A-Z]{3}\d{2}|(M[A-Z]?\d{3}|V[A-Z]?\d{3})|(X\d{3})|(\d{5})|(S\d{3})|(\d{4}S))/;
      exceptionRegex = /^(([EZ]{2}-\d{5})|(CL?-\d{5})|(EL-\d{3}[A-Z]{2})|(EV-\d{3}[A-Z]{2}))$/;
      return RegExp(
        `^(((${regionRegex.source})|(${/\d{2}/.source}))${separatorRegex.source}${registrationRegex.source})$|${exceptionRegex.source}`
      );
    case supportedCountries.SI:
      return /^((CE|GO|KK|KP|KR|LJ|MB|MS|NM|PO|SG)-([A-Z]{2}-\d{3}|[A-Z]\d-\d{3}|\d{2}-\d{2}[A-Z]|\d{2}-\d{3}|\d{2}-\d[A-Z]{2}|\d{3}-[A-Z]{2}|\d{2}-[A-Z]{3}|[\dA-Z]{3,7}-[A-Z]))$/;
    case supportedCountries.UA:
      regionRegex = useRegionCodes ? RegExp(`(${districts.UA.join("|")})`) : /[A-Z]{2}/;
      return RegExp(
        `^${regionRegex.source}${/\d{4}/.source}${/[A-Z]{2}/.source}$`
        // ^[A-Z]{2}\d{4}[A-Z]{2}$
      );
    case supportedCountries.UK:
      return /^(([A-HK-PRSVWY][A-HJ-PR-Y]\d{2}[A-Z]{3})|([A-Z]\d{3}[A-Z]{3})|([A-Z]{1,2}\d{1,2}[A-Z]{0,3}))$/;
    case supportedCountries.XX:
      return /^[0-9A-ZÖÜÄ-]{1,20}$/;
    default:
      throw new Error("Country not supported");
  }
}

const regexListLiberal = {
  AT: getCountryRegex(supportedCountries.AT, true, false),
  BA: getCountryRegex(supportedCountries.BA, true, false),
  CH: getCountryRegex(supportedCountries.CH, true, false),
  DE: getCountryRegex(supportedCountries.DE, true, false),
  HR: getCountryRegex(supportedCountries.HR, true, false),
  IT: getCountryRegex(supportedCountries.IT, true, false),
  RS: getCountryRegex(supportedCountries.RS, true, false)
};
const liberalRegexList = regexListLiberal;

const regexList = {
  AT: getCountryRegex(supportedCountries.AT, false, false),
  BE: getCountryRegex(supportedCountries.BE, false, false),
  BA: getCountryRegex(supportedCountries.BA, false, false),
  BG: getCountryRegex(supportedCountries.BG, false, false),
  BY: getCountryRegex(supportedCountries.BY, false, false),
  CH: getCountryRegex(supportedCountries.CH, false, false),
  CZ: getCountryRegex(supportedCountries.CZ, false, false),
  DE: getCountryRegex(supportedCountries.DE, false, false),
  DK: getCountryRegex(supportedCountries.DK, false, false),
  ES: getCountryRegex(supportedCountries.ES, false, false),
  FI: getCountryRegex(supportedCountries.FI, false, false),
  FR: getCountryRegex(supportedCountries.FR, false, false),
  GR: getCountryRegex(supportedCountries.GR, false, false),
  HU: getCountryRegex(supportedCountries.HU, false, false),
  HR: getCountryRegex(supportedCountries.HR, false, false),
  IT: getCountryRegex(supportedCountries.IT, false, false),
  LU: getCountryRegex(supportedCountries.LU, false, false),
  LT: getCountryRegex(supportedCountries.LT, false, false),
  NL: getCountryRegex(supportedCountries.NL, false, false),
  NO: getCountryRegex(supportedCountries.NO, false, false),
  PL: getCountryRegex(supportedCountries.PL, false, false),
  PT: getCountryRegex(supportedCountries.PT, false, false),
  RO: getCountryRegex(supportedCountries.RO, false, false),
  RS: getCountryRegex(supportedCountries.RS, false, false),
  SE: getCountryRegex(supportedCountries.SE, false, false),
  SI: getCountryRegex(supportedCountries.SI, false, false),
  SK: getCountryRegex(supportedCountries.SK, false, false),
  UA: getCountryRegex(supportedCountries.UA, false, false),
  UK: getCountryRegex(supportedCountries.UK, false, false),
  XX: getCountryRegex(supportedCountries.XX, false, false)
};

const plateCountryFormatMap = /* @__PURE__ */ new Map([
  ["AT", "W1234A"],
  ["BE", "CA7845XC"],
  ["CH", "ZH1234"],
  ["CZ", "2A33824"],
  ["DE", "M-PP123"],
  ["DK", "AZ12345"],
  ["FR", "AB-229-AA"],
  ["HR", "RI761-CC"],
  ["HU", "ABAB-123"],
  ["IT", "AB123CD"],
  ["LU", "AZ1234"],
  ["NL", "J-206-FV"],
  ["PL", "WW3008J"],
  ["SE", "ABC123"],
  ["SI", "LJ-898-KP"],
  ["UA", "AB1234AB"]
]);
function parsePlate(plate, country, useLiberalSeparators = true, useRegionCodes = true) {
  const regexResult = validatePlate(plate, country, useLiberalSeparators, useRegionCodes);
  if (regexResult) {
    return {
      success: true,
      data: plate,
      validFormat: plateCountryFormatMap.get(country) || "",
      error: ""
    };
  } else {
    let error = "";
    let validFormat = "";
    const firstSubstring = plate.toUpperCase().substring(0, 1);
    const secondSubstring = plate.toUpperCase().substring(0, 2);
    const thirdSubstring = plate.toUpperCase().substring(0, 3);
    switch (country) {
      case supportedCountries.AT: {
        const validDistrict = districts.AT.includes(firstSubstring) || districts.AT.includes(secondSubstring);
        error = validDistrict ? "invalid_plate" : "invalid_district";
        validFormat = "K510BV";
        break;
      }
      case supportedCountries.CH: {
        const validDistrict = districts.CH.includes(secondSubstring);
        error = validDistrict ? "invalid_plate" : "invalid_district";
        validFormat = "SG197052";
        break;
      }
      case supportedCountries.DE: {
        const validDistrict = districts.DE.includes(firstSubstring) || districts.DE.includes(secondSubstring) || districts.DE.includes(thirdSubstring);
        error = validDistrict ? "invalid_plate" : "invalid_district";
        validFormat = "M-PP123";
        break;
      }
      case supportedCountries.SI: {
        const validDistrict = districts.SI.includes(secondSubstring);
        error = validDistrict ? "invalid_plate" : "invalid_district";
        validFormat = "LJ-898-KP";
        break;
      }
      default:
        error = "invalid_plate";
        validFormat = plateCountryFormatMap.get(country) || "";
        break;
    }
    return {
      success: false,
      data: plate,
      validFormat,
      error
    };
  }
}

const containsDefaultSeparatorRegex = /[.\-\s]/;
const defaultSeparator = "-";
const excludeCountries = /* @__PURE__ */ new Set(
  [
    // XX is not a valid country
    supportedCountries.XX,
    // BE is not a valid country for reconstruction, because it has no distinct plate format
    supportedCountries.BE
  ]
);
const defaultReconstructionOptions = {
  includeNormalizedPlates: false,
  includePlatesWithOptionalCharacters: false,
  includeInconsistentResults: false,
  includeExcludedCountries: false,
  includeEmptyCountry: false,
  includeReconstructedOptionalCharacters: false
};
const potentialCountriesWithDistricts = new Set(Object.keys(districts));
function reconstructPotentialPlatesWithOptions(plate, country, options = defaultReconstructionOptions) {
  const potentialCountries = country ? [country] : reconstructPlateCountry(plate, options);
  const potentialPlates = [];
  if (!containsDefaultSeparatorRegex.test(plate)) {
    for (const potentialCountry of potentialCountries) {
      if (!potentialCountriesWithDistricts.has(potentialCountry)) {
        potentialPlates.push({
          plate,
          country: potentialCountry
        });
        continue;
      }
      for (const potentialDistrict of districts[potentialCountry]) {
        if (!plate.startsWith(potentialDistrict))
          continue;
        const potentialPlate = plate.replace(new RegExp(potentialDistrict), `${potentialDistrict}${defaultSeparator}`);
        if (validatePlate(potentialPlate, potentialCountry, true, true)) {
          potentialPlates.push({
            plate: potentialPlate,
            country: potentialCountry
          });
        }
      }
    }
  } else {
    for (const potentialCountry of potentialCountries) {
      potentialPlates.push({
        plate,
        country: potentialCountry
      });
    }
  }
  if (options.includeReconstructedOptionalCharacters) {
    const platesWithReconstructedOptionalCharacters = potentialPlates.flatMap((potentialPlate) => reconstructPotentialPlatesWithOptionalCharacters(potentialPlate.plate, potentialPlate.country));
    potentialPlates.push(...platesWithReconstructedOptionalCharacters);
  }
  if (options.includeInconsistentResults) {
    const platesWithoutSeparators = potentialPlates.map((potentialPlate) => ({ plate: removeAllSpecialCharacters(potentialPlate.plate), country: potentialPlate.country }));
    potentialPlates.push(...platesWithoutSeparators);
  }
  if (options.includeInconsistentResults) {
    const platesWithoutOptionalCharacters = potentialPlates.map((potentialPlate) => removeOptionalCharacters(potentialPlate.plate, potentialPlate.country));
    potentialPlates.push(...platesWithoutOptionalCharacters);
  }
  if (options.includeNormalizedPlates) {
    const platesNormalized = potentialPlates.map((potentialPlate) => ({ plate: normalizePlate(potentialPlate.plate, potentialPlate.country), country: potentialPlate.country }));
    potentialPlates.push(...platesNormalized);
  }
  if (options.includeEmptyCountry) {
    const emptyCountryPlates = potentialPlates.map((potentialPlate) => ({ plate: potentialPlate.plate, country: supportedCountries.XX }));
    potentialPlates.push(...emptyCountryPlates);
  }
  return potentialPlates.filter((value, index, self) => self.findIndex((t) => t.plate === value.plate && t.country === value.country) === index);
}
function reconstructAllPotentialPlates(plate, country) {
  return reconstructPotentialPlatesWithOptions(plate, country, {
    includeNormalizedPlates: true,
    includePlatesWithOptionalCharacters: true,
    includeInconsistentResults: true,
    includeExcludedCountries: true,
    includeEmptyCountry: true,
    includeReconstructedOptionalCharacters: true
  });
}
function reconstructAllPotentialPlatesWithAllHyphenations(plate, country) {
  const allCountries = country ? [country] : Object.values(supportedCountries);
  const allPossibleNormalizedPlates = /* @__PURE__ */ new Set();
  const reconstructedPlates = [];
  for (const country2 of allCountries) {
    const normalizedPlate = normalizePlate(plate, country2);
    allPossibleNormalizedPlates.add(normalizedPlate);
  }
  for (const potentialPlate of allPossibleNormalizedPlates) {
    for (const potentialCountry of allCountries) {
      if (validatePlate(potentialPlate, potentialCountry, false, true)) {
        reconstructedPlates.push({ plate: potentialPlate, country: potentialCountry });
      }
    }
    for (let dash1 = 1; dash1 < potentialPlate.length; dash1++) {
      const newPlateWith1Dash = potentialPlate.slice(0, dash1) + defaultSeparator + potentialPlate.slice(dash1);
      for (const potentialCountry of allCountries) {
        if (validatePlate(newPlateWith1Dash, potentialCountry, false, true)) {
          reconstructedPlates.push({ plate: newPlateWith1Dash, country: potentialCountry });
        }
      }
      for (let dash2 = dash1 + 1; dash2 < potentialPlate.length; dash2++) {
        const newPlateWith2Dash = potentialPlate.slice(0, dash1) + defaultSeparator + potentialPlate.slice(dash1, dash2) + defaultSeparator + potentialPlate.slice(dash2);
        for (const potentialCountry of allCountries) {
          if (validatePlate(newPlateWith2Dash, potentialCountry, false, true)) {
            reconstructedPlates.push({ plate: newPlateWith2Dash, country: potentialCountry });
          }
        }
      }
    }
  }
  reconstructedPlates.forEach((potentialPlate) => {
    const normalizedPlate = normalizePlate(potentialPlate.plate, potentialPlate.country);
    reconstructedPlates.push({ plate: normalizedPlate, country: potentialPlate.country });
  });
  if (allCountries.includes(supportedCountries.DE)) {
    const germanPlates = reconstructedPlates.filter((potentialPlate) => potentialPlate.country === supportedCountries.DE);
    const germanPlatesWithOptionalCharacters = germanPlates.flatMap((potentialPlate) => reconstructPotentialPlatesWithOptionalCharacters(potentialPlate.plate, potentialPlate.country));
    reconstructedPlates.push(...germanPlatesWithOptionalCharacters);
  }
  return reconstructedPlates.filter((value, index, self) => self.findIndex((t) => t.plate === value.plate && t.country === value.country) === index);
}
function reconstructPlateCountry(plate, options) {
  const potentialCountries = [];
  for (const potentialCountry of Object.values(supportedCountries)) {
    if (!options?.includeExcludedCountries && excludeCountries.has(potentialCountry))
      continue;
    if (validatePlate(plate, potentialCountry, true, true))
      potentialCountries.push(potentialCountry);
  }
  return potentialCountries;
}
function reconstructPotentialPlatesWithOptionalCharacters(plate, country) {
  switch (country) {
    case supportedCountries.DE:
      if (plate.endsWith("H") || plate.endsWith("E"))
        return [];
      else
        return [{ plate: `${plate}E`, country: supportedCountries.DE }, { plate: `${plate}H`, country: supportedCountries.DE }];
    default:
      return [];
  }
}
function removeOptionalCharacters(plate, country) {
  switch (country) {
    case supportedCountries.DE:
      if (plate.endsWith("H") || plate.endsWith("E"))
        return { plate: plate.slice(0, -1), country: supportedCountries.DE };
      else
        return { plate, country: supportedCountries.DE };
    default:
      return { plate, country: country || supportedCountries.XX };
  }
}

const removeAllSpecialCharactersRegEx = /[^A-Z0-9ÄÖÜ\s]/g;
function normalizePlate(plate, plateCountry) {
  if (!plateCountry) {
    const potentialCountries = reconstructPlateCountry(plate);
    if (potentialCountries.length === 1)
      plateCountry = potentialCountries[0];
  }
  const uppercasePlate = plate.toUpperCase();
  switch (plateCountry) {
    case supportedCountries.DE:
      return removeAllSpecialCharacters(uppercasePlate)?.replace(/(E|H)$/, "");
    default:
      return removeAllSpecialCharacters(uppercasePlate);
  }
}
function removeAllSpecialCharacters(str) {
  return str?.replaceAll(removeAllSpecialCharactersRegEx, "");
}

const defaultFindBestPlateOptions = {
  excludeWrongHyphenationIfPossible: true,
  excludeWrongHyphenation: false,
  excludeCountryMismatch: false
};
function findBestPlate(potentialPlateObjects, originalPlate, plateAttributeName, options = defaultFindBestPlateOptions) {
  options = { ...defaultFindBestPlateOptions, ...options };
  const { originalPlateCountry, countryAttributeName, excludeCountryMismatch, excludeWrongHyphenationIfPossible, excludeWrongHyphenation } = options || {};
  const countriesForDifferentHandling = excludeCountryMismatch && originalPlateCountry ? [originalPlateCountry] : [supportedCountries.DE, supportedCountries.XX];
  let matches = potentialPlateObjects.filter((object) => {
    const objectPlate = object[plateAttributeName];
    for (const country of countriesForDifferentHandling) {
      const normalizedFilterPlate = normalizePlate(objectPlate, country);
      const normalizedOriginalPlate = normalizePlate(originalPlate, country);
      const plateMatch = normalizedFilterPlate === normalizedOriginalPlate;
      if (plateMatch)
        return true;
    }
    return false;
  });
  if (excludeCountryMismatch && originalPlateCountry) {
    matches = matches.filter((object) => {
      const objectCountry = countryAttributeName ? object[countryAttributeName] : void 0;
      return objectCountry ? objectCountry === originalPlateCountry : true;
    });
  }
  if (excludeWrongHyphenationIfPossible || excludeWrongHyphenation) {
    const matchesWithoutWrongHyphenation = matches.filter((plateObject) => {
      const objectPlate = plateObject[plateAttributeName];
      for (const country of countriesForDifferentHandling) {
        const originalPlateWithoutOptionalCharacters = removeOptionalCharacters(originalPlate, country).plate;
        const objectPlateWithoutOptionalCharacters = removeOptionalCharacters(objectPlate, country).plate;
        const originalPlateNormalized = normalizePlate(originalPlateWithoutOptionalCharacters, country);
        const objectPlateNormalized = normalizePlate(objectPlateWithoutOptionalCharacters, country);
        const plateMatch = objectPlateWithoutOptionalCharacters === originalPlateWithoutOptionalCharacters || objectPlateNormalized === originalPlateWithoutOptionalCharacters || objectPlateWithoutOptionalCharacters === originalPlateNormalized;
        if (plateMatch)
          return true;
      }
      return false;
    });
    if (excludeWrongHyphenation) {
      matches = matchesWithoutWrongHyphenation;
    } else if (excludeWrongHyphenationIfPossible && matchesWithoutWrongHyphenation.length > 0) {
      matches = matchesWithoutWrongHyphenation;
    }
  }
  return matches;
}

export { countryCodeToIVRCode, districts, findBestPlate, liberalRegexList, normalizePlate, parsePlate, reconstructAllPotentialPlates, reconstructAllPotentialPlatesWithAllHyphenations, reconstructPlateCountry, reconstructPotentialPlatesWithOptions, regexList, regexListLiberal, supportedCountries, supportedCountriesList, validatePlate };
